export default [
  {
    type: 'menu',
    name: 'Dashboard',
    to: '/',
    icon: 'home',
    exact: true,
    permission: 4
  },
  {
    type: 'submenu',
    name: 'Estatísticas',
    permission: 6,
    icon: 'bar-chart',
    exact: false,
    to: [
      {
        type: 'menu',
        name: 'Clientes',
        to: '/statistics/clients',
        external_icon: true,
        icon: 'clients.svg',
        exact: true,
        permission: 6
      },
      {
        type: 'menu',
        name: 'Serviços',
        to: '/statistics/services',
        icon: 'unordered-list',
        exact: true,
        permission: 6
      },
      {
        type: 'menu',
        name: 'Comissões',
        to: '/statistics/commissions',
        icon: 'euro',
        exact: true,
        permission: 6
      }
    ]
  },
  // Old process area
  // {
  //   type: 'menu',
  //   name: 'Processos (ANTIGO)',
  //   to: '/process-old',
  //   icon: 'close',
  //   exact: false,
  //   permission: 4
  // },
  {
    type: 'menu',
    name: 'Processos',
    to: '/process',
    icon: 'solution',
    exact: false,
    permission: 4
  },
  {
    type: 'menu',
    name: 'Pagamentos Pendentes',
    to: '/pending-payments',
    icon: 'money-collect',
    exact: true,
    permission: 1
  },
  {
    type: 'menu',
    name: 'Tarefas',
    to: '/tasks',
    external_icon: true,
    icon: 'clipboard.svg',
    exact: false,
    permission: 4
  },
  {
    type: 'menu',
    name: 'Lemania',
    to: '/lemania',
    icon: 'bank',
    exact: false,
    permission: 4
  },
  {
    type: 'menu',
    name: 'Comissões',
    to: '/commissions',
    icon: 'euro',
    exact: false,
    permission: 6
  },
  {
    type: 'submenu',
    name: 'Documentos',
    small: true,
    baseUrl: '/documents',
    to: [
      {
        type: 'menu',
        name: 'Categorias',
        to: '/documents/categories',
        icon: 'folder',
        exact: true
      },
      {
        type: 'menu',
        name: 'Listagem',
        to: '/documents/list',
        icon: 'unordered-list',
        exact: true
      }
    ],
    icon: 'file-pdf',
    exact: false,
    permission: 1
  },
  {
    type: 'menu',
    name: 'Logs',
    to: '/logs',
    icon: 'database',
    exact: false,
    permission: 4
  },
  {
    type: 'menu',
    name: 'Email Logs',
    to: '/email-logs',
    icon: 'container',
    exact: false,
    permission: 1
  },
  {
    type: 'menu',
    name: 'Comunicações',
    to: '/communications',
    icon: 'mail',
    exact: false,
    permission: 1
  },
  {
    type: 'menu',
    name: 'Códigos Promocionais',
    to: '/promo-codes',
    external_icon: true,
    icon: 'promotion.svg',
    exact: false,
    permission: 1
  },
  {
    type: 'menu',
    name: 'Clientes',
    to: '/clients',
    external_icon: true,
    icon: 'clients.svg',
    exact: false,
    permission: 1
  },
  {
    type: 'menu',
    name: 'Arquivo',
    to: '/archive',
    external_icon: true,
    icon: 'archive.svg',
    exact: false,
    permission: 4
  },
  {
    type: 'menu',
    name: 'Moradas',
    to: '/address',
    icon: 'home',
    exact: false,
    permission: 1
  },
  {
    type: 'submenu',
    name: 'Website',
    to: [
      {
        type: 'menu',
        name: 'Pedidos Contacto',
        to: '/contact-requests',
        icon: 'contacts',
        exact: false,
        permission: 1
      },
      {
        type: 'menu',
        name: 'Contactos Newsletter',
        to: '/newsletter',
        icon: 'mail',
        exact: false,
        permission: 1
      },
      {
        type: 'menu',
        name: 'Pedidos Documentos',
        to: '/document-requests',
        icon: 'form',
        exact: false,
        permission: 1
      },
      {
        type: 'menu',
        name: 'Serviços Recomendados',
        to: '/clients-recommend',
        icon: 'star',
        exact: false,
        permission: 1
      },
      {
        type: 'submenu',
        name: 'Serviços',
        small: true,
        baseUrl: '/services',
        to: [
          {
            type: 'menu',
            name: 'Banner',
            to: '/services/banner',
            icon: 'picture',
            exact: true
          },
          {
            type: 'menu',
            name: 'Listagem',
            to: '/services/list',
            icon: 'unordered-list',
            exact: true
          }
        ],
        icon: 'folder',
        exact: false,
        permission: 1
      },
      {
        type: 'submenu',
        name: 'Outros Serviços',
        small: true,
        baseUrl: '/other-services',
        to: [
          {
            type: 'menu',
            name: 'Seguros',
            to: '/other-services/insurance',
            icon: 'picture',
            exact: true
          },
          {
            type: 'menu',
            name: 'Créditos',
            to: '/other-services/credit',
            icon: 'picture',
            exact: true
          },
          {
            type: 'menu',
            name: '2º Pilar',
            to: '/other-services/2-pilar',
            icon: 'picture',
            exact: true
          }
        ],
        icon: 'folder',
        exact: false,
        permission: 1
      },
      {
        type: 'submenu',
        name: 'Homepage',
        small: true,
        baseUrl: '/homepage',
        to: [
          {
            type: 'menu',
            name: 'Banner',
            to: '/homepage/banner',
            icon: 'picture',
            exact: true
          },
          {
            type: 'menu',
            name: 'Imóveis',
            to: '/homepage/estate',
            icon: 'file-text',
            exact: true
          },
          {
            type: 'menu',
            name: 'Documentos Digitais',
            to: '/homepage/documents',
            icon: 'file-text',
            exact: true
          },
          {
            type: 'menu',
            name: 'Serviços',
            to: '/homepage/services',
            icon: 'file-text',
            exact: true
          },
        ],
        icon: 'desktop',
        exact: false,
        permission: 1
      },
      {
        type: 'submenu',
        name: 'Como funciona',
        small: true,
        baseUrl: '/how-it-works',
        to: [
          {
            type: 'menu',
            name: 'Banner',
            to: '/how-it-works/banner',
            icon: 'picture',
            exact: true,
          },
          {
            type: 'menu',
            name: 'Vídeo',
            to: '/how-it-works/video',
            icon: 'play-circle',
            exact: true,
          },
          {
            type: 'menu',
            name: 'Tipos de Processo (Introdução)',
            to: '/how-it-works/process-types-intro',
            icon: 'file-text',
            exact: true,
          },
          {
            type: 'menu',
            name: 'Tipos de Processo',
            to: '/how-it-works/process-types',
            icon: 'bars',
            exact: true,
          },
          {
            type: 'menu',
            name: 'Vantagens (Introdução)',
            to: '/how-it-works/benefits-intro',
            icon: 'file-text',
            exact: false,
          },
          {
            type: 'menu',
            name: 'Vantagens',
            to: '/how-it-works/benefits',
            icon: 'bars',
            exact: false,
          }
        ],
        icon: 'safety-certificate',
        exact: false,
        permission: 1
      },
      {
        type: 'submenu',
        name: 'Sobre a empresa',
        small: true,
        baseUrl: '/company',
        to: [
          {
            type: 'menu',
            name: 'Banner',
            to: '/company/banner',
            icon: 'picture',
            exact: true,
          },
          {
            type: 'menu',
            name: 'Sobre nós',
            to: '/company/about-us',
            icon: 'file-text',
            exact: true,
          },
          {
            type: 'menu',
            name: 'Missão',
            to: '/company/mission',
            icon: 'file-text',
            exact: true,
          },
          {
            type: 'menu',
            name: 'Equipa (Introdução)',
            to: '/company/team-intro',
            icon: 'file-text',
            exact: false,
          },
          {
            type: 'menu',
            name: 'Equipa',
            to: '/company/team',
            icon: 'team',
            exact: false,
          },
          {
            type: 'menu',
            name: 'Números',
            to: '/company/numbers',
            field_number: true,
            exact: false,
          }
        ],
        icon: 'bank',
        exact: false,
        permission: 1
      },
      {
        type: 'submenu',
        name: 'Blog',
        small: true,
        baseUrl: '/blog',
        to: [
          {
            type: 'menu',
            name: 'Banner',
            to: '/blog/banner',
            icon: 'picture',
            exact: true
          },
          {
            type: 'menu',
            name: 'Categorias',
            to: '/blog/categories',
            icon: 'tag',
            exact: true
          },
          {
            type: 'menu',
            name: 'Listagem',
            to: '/blog/list',
            icon: 'unordered-list',
            exact: true
          }
        ],
        icon: 'read',
        exact: false,
        permission: 1
      },
      {
        type: 'submenu',
        name: 'Imobiliário',
        small: true,
        baseUrl: '/estate',
        to: [
          {
            type: 'menu',
            name: 'Banner',
            to: '/estate/banner',
            icon: 'picture',
            exact: true
          },
          {
            type: 'menu',
            name: 'Tipologias',
            to: '/estate/types',
            icon: 'tag',
            exact: true
          },
          {
            type: 'menu',
            name: 'Características',
            to: '/estate/features',
            icon: 'tag',
            exact: true
          },
          {
            type: 'menu',
            name: 'Listagem',
            to: '/estate/list',
            icon: 'unordered-list',
            exact: true
          }
        ],
        icon: 'home',
        exact: false,
        permission: 1
      },
      {
        type: 'submenu',
        name: 'Documentos',
        small: true,
        baseUrl: '/digital-documents',
        to: [
          {
            type: 'menu',
            name: 'Banner',
            to: '/digital-documents/banner',
            icon: 'picture',
            exact: true
          },
          {
            type: 'menu',
            name: 'Listagem',
            to: '/digital-documents/list',
            icon: 'unordered-list',
            exact: true
          }
        ],
        icon: 'file-pdf',
        exact: false,
        permission: 1
      },
      {
        type: 'submenu',
        name: 'FAQs',
        small: true,
        baseUrl: '/faqs',
        to: [
          {
            type: 'menu',
            name: 'Introdução',
            to: '/faqs/intro',
            icon: 'file-text',
            exact: true
          },
          {
            type: 'menu',
            name: 'Listagem',
            to: '/faqs/list',
            icon: 'unordered-list',
            exact: true
          },
        ],
        icon: 'question-circle',
        exact: false,
        permission: 1
      },
      {
        type: 'menu',
        name: 'Páginas Dinâmicas',
        to: '/dynamic-pages',
        icon: 'copy',
        exact: false,
        permission: 1
      },
      {
        type: 'menu',
        name: 'Metatags',
        to: '/metatags',
        icon: 'tags',
        exact: false,
        permission: 1
      },
    ],
    icon: 'global',
    exact: false,
    permission: 1
  },
  {
    type: 'submenu',
    name: 'Administração',
    to: [
      {
        type: 'menu',
        name: 'Informações Gerais',
        to: '/configs',
        icon: 'read',
        exact: false,
        permission: 1
      },
      {
        type: 'menu',
        name: 'Templates de Email',
        to: '/email-templates',
        icon: 'mail',
        exact: false,
        permission: 1
      },
      {
        type: 'menu',
        name: 'Templates Instituições',
        to: '/institutions-templates',
        icon: 'container',
        exact: false,
        permission: 1
      },
      {
        type: 'menu',
        name: 'Traduções',
        to: '/translations',
        icon: 'global',
        exact: true,
        permission: 1
      },
      {
        type: 'menu',
        name: 'Utilizadores',
        to: '/users',
        icon: 'team',
        exact: false,
        permission: 1
      },
      {
        type: 'menu',
        name: 'Perfil',
        to: '/my-profile',
        icon: 'user',
        exact: false,
        permission: 1
      },
    ],
    icon: 'setting',
    exact: false,
    permission: 1
  },
  {
    type: 'menu',
    name: 'Imóveis',
    to: '/estate/list',
    icon: 'unordered-list',
    exact: false,
    permission: 7
  },
  {
    type: 'menu',
    name: 'Perfil',
    to: '/my-profile',
    icon: 'user',
    exact: false,
    permission: 5
  }
];