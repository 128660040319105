import { PageContainer, PageTitle, HeaderContainer, HeaderTitle } from '../../styles/BasicStyles';
import { GetUserProcessFullList, GetPendingPaymentsList } from '../../infra/requests/ProcessRequests';
import { GetClientsList } from '../../infra/requests/ClientsRequests';
import { connect } from 'react-redux';
import { StateLabel } from '../process/styles';
import React, { Component } from 'react';
import moment from 'moment-timezone';
import Table from '../../components/generic/table/Table';
import PendingPaymentsFilters from './PendingPaymentsFilters';

const states = [
  {
    min: 0,
    max: 0,
    bgColor: '#D1FAE5',
    fontColor: '#065F46'
  },
  {
    min: 1,
    max: 5,
    bgColor: '#FEF3C7',
    fontColor: '#92400E'
  },
  {
    min: 6,
    max: 10,
    bgColor: '#FFE8CC',
    fontColor: '#A32E00'
  },
  {
    min: 11,
    max: 0,
    bgColor: '#ffcdd2',
    fontColor: '#e53935'
  },
];

class PendingPaymentsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    sort: '',
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Data',
        dataIndex: 'purchase_date',
        render: (value) => `${moment(value).format('DD-MM-YYYY, HH:mm')}h`,
        sorter: true,
        width: '12%'
      },
      {
        title: 'Processo',
        dataIndex: 'ref',
        sorter: true,
        width: '10%'
      },
      {
        title: 'Tipo',
        dataIndex: 'type',
        render: (data) => this.renderType(data),
        width: '10%'
      },
      {
        title: 'Descrição',
        dataIndex: 'title',
        sorter: true,
        width: '28%'
      },
      {
        title: 'Valor total',
        key: 'price',
        render: (data) => this.renderPrice(data),
        sorter: true,
        width: '10%'
      },
      {
        title: 'Aviso Ativo?',
        dataIndex: 'send_pending_payments_emails',
        render: (data) => this.renderState(data),
        sorter: true,
        width: '12%'
      },
      {
        title: 'Nº Avisos',
        dataIndex: 'pending_payment_email_count',
        render: (data) => data || 0,
        sorter: true,
        width: '10%'
      },
      {
        title: 'Último Aviso',
        dataIndex: 'last_pending_payment_email',
        render: (value) => value ? `${moment(value).format('DD-MM-YYYY, HH:mm')}h` : 'N/A',
        sorter: true,
        width: '12%'
      },
      {
        title: 'Dias de Atraso',
        dataIndex: 'delay',
        render: (data) => this.renderDelay(data),
        sorter: true,
        width: '10%'
      }
    ],
    rows: [],
    total: 0,
    process: [],
    clients: []
  };

  componentDidMount = async () => {
    const { user, history } = this.props;
    
    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    await this.updateDataTable();
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  renderType = (type) => {
    if(type === 'process') return 'Serviço';
    if(type === 'payment') return 'Outros Pagamentos';
    return 'N/A';
  };

  renderPrice = (data) => {
    if(data.currency === 1) return `${data.price}CHF`;
    if(data.currency === 2) return `${data.price}€`;
    return 'N/A';
  };

  renderDelay = (delay) => {
    const state = states.find(elem => {
      if(elem.min === 0 && elem.max === 0) return delay === 0;
      if(elem.max === 0) return delay >= elem.min;
      return delay >= elem.min && delay <= elem.max;
    });

    return <StateLabel bgColor={state?.bgColor || '#bdbdbd'} fontColor={state?.fontColor || '#212121'}>{delay}</StateLabel>;
  };

  renderState = (value) => {
    if(value) {
      return (
        <StateLabel
          bgColor='#389e0d'
          fontColor='#ffffff'
        >
          Sim
        </StateLabel>
      );
    }
    return (
      <StateLabel
        bgColor='#cf1322'
        fontColor='#ffffff'
      >
        Não
      </StateLabel>
    );
  };

  updateDataTable = async () => {
    this.setState({ loading: true });

    try {
      const { currentPage, pageSize, filters, sort } = this.state;

      const result = await GetPendingPaymentsList(currentPage, pageSize, filters, sort);
      const process = await GetUserProcessFullList();
      const clients = await GetClientsList();

      const processList = [];
      if(process?.data?.length > 0) {
        process.data.forEach(elem => {
          processList.push({
            _id: elem._id,
            name: `${elem.ref} - ${elem.full_name}`
          })
        });
      }
      
      this.setState({
        rows: result.data?.items || [],
        total: result.data?.total || 0,
        process: processList,
        clients: clients?.data || [],
        ready: true, 
        loading: false
      });
    } 
    catch(e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  onSortChange = (pagination, filters, sort) => {
    this.setState({
      sort: JSON.stringify({ column: sort.columnKey, direction: sort.order })
    }, this.updateDataTable);
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize, process, clients } = this.state;

    return (
      <>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle noMarginBottom={true}>Pagamentos Pendentes</PageTitle>
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <PendingPaymentsFilters 
            queryChange={this.handleFilterChange} 
            processList={process}
            clients={clients}
          />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem pagamentos pendentes!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            onSortChange={this.onSortChange}
          />
        </PageContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(PendingPaymentsPage);
