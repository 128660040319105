import { Row, Col, notification } from 'antd';
import { ActionsWrapper, ActionsCol } from '../styles';
import { CardWrapper, Card, CardContainer, CardItem, CardTitle, CardValue } from '../../styles';
import { initialize, Field, reduxForm } from 'redux-form';
import { StateLabel } from '../../styles';
import { connect } from 'react-redux';
import { BaseForm } from '../../../../styles/BasicStyles';
import { UpdateProcessSendEmails } from  '../../../../infra/requests/ProcessRequests';
import React, { useState } from 'react';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import CheckboxInput from '../../../../components/generic/inputs/CheckboxInput';

const EmailsForm = ({ processInfo, getInfo, dispatch, handleSubmit, pristine }) => {
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const openEditForm = () => {
    dispatch(initialize('edit_emails_form', { ...processInfo }));
    setEdit(true);
  };

  const renderState = (value) => {
    if(value) return <StateLabel bgColor='#389e0d' fontColor='#ffffff'>Sim</StateLabel>;
    return <StateLabel bgColor='#cf1322' fontColor='#ffffff'>Não</StateLabel>;
  };

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const { success } = await UpdateProcessSendEmails(processInfo._id, values);

      if(success) {
        notification.success({
          message: 'Dados alterados com sucesso!'
        });

        setEdit(false);
        await getInfo();
      }

      setLoading(false);
    }
    catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  return (
    <CardWrapper gutter={24}>
      <Card xs={24}>
        {
          edit ?
          <BaseForm onSubmit={handleSubmit(onSubmit)}>
            <CardContainer>
              <ActionsWrapper alignTop='true'>
                <ActionsCol></ActionsCol>
                <ActionsCol>
                  <BaseButton
                    htmlType='button'
                    type='secondary'
                    text='Cancelar'
                    style={{ float: 'right', marginRight: '8px' }}
                    disabled={loading}
                    onClick={() => setEdit(false)}
                  />
                  <BaseButton
                    htmlType='submit'
                    type='primary'
                    text='Guardar'
                    style={{ float: 'right' }}
                    loading={loading}
                    disabled={loading || pristine}
                    onClick={handleSubmit(onSubmit)}
                  />
                </ActionsCol>
              </ActionsWrapper>
              <Row gutter={24}>
                <Col lg={12} xs={24}>
                  <Field
                    component={CheckboxInput}
                    name={`send_pending_payments_emails`}
                    label={'Enviar emails sobre pagamentos pendentes?'}
                    importantLabel={true}
                    inlineCheckbox={true}
                  />
                </Col>
              </Row>
            </CardContainer>
          </BaseForm>
          :
          <>
            <CardContainer>
              <ActionsWrapper>
                <ActionsCol></ActionsCol>
                <ActionsCol>
                  {
                    !processInfo.archived &&
                    <BaseButton
                      htmlType='button'
                      type='secondary'
                      icon='edit'
                      text='Editar'
                      style={{ float: 'right' }}
                      onClick={() => openEditForm()}
                    />
                  }
                </ActionsCol>
              </ActionsWrapper>
              <Row gutter={24} style={{ marginTop: '5px' }}>
                <Col xs={24}>
                  <CardItem center>
                    <CardTitle style={{ width: '350px' }}>Enviar emails sobre pagamentos pendentes?</CardTitle>
                    <CardValue style={{ maxWidth: '600px' }}>{renderState(processInfo.send_pending_payments_emails)}</CardValue>
                  </CardItem>
                </Col>
              </Row>
            </CardContainer>
          </>
        }
      </Card>
    </CardWrapper>
  );
};

const DataForm = reduxForm({
  form: 'edit_emails_form'
})(EmailsForm);

export default connect()(DataForm);