import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetCategory,
  UpdateCategory,
  CreateCategory
} from '../../infra/requests/DocumentsRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextInput from '../../components/generic/inputs/TextInput';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";

const validations = FormValidator.make({
  title: 'required'
});

class ManageDocumentsCategoryPage extends Component {
  state = { 
    isNew: false, 
    loading: true 
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu,
      user,
      history
    } = this.props;

    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    CloseMenu();

    if(params.id) {
      const result = await GetCategory(params.id);
      dispatch(
        initialize('manage_documents_categories_form', {
          ...result.data,
        })
      );

      this.setState({ loading: false });
    } 
    else {
      this.setState({
        isNew: true,
        loading: false,
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;

      const payload = FlattenToFormData(values);
      const { success } = params.id ? await UpdateCategory(params.id, payload) : await CreateCategory(payload);

      if (success) return history.push('/documents/categories');
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/documents/categories');
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    const { isNew, loading } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <React.Fragment>
        <ManageFormHeader
          title='Documentos'
          breadcrumbs={['Categoria', title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'secondary',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row>
              <Row gutter={24}>
                <Col md={12} xs={24} offset={6}>
                  <Field
                    component={TextInput}
                    name='title'
                    type='text'
                    label={'Título *'}
                    placeholder={'Título'}
                  />
                </Col>
              </Row>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageDocumentsCategoryPage = reduxForm({
  form: 'manage_documents_categories_form',
  validate: validations,
})(ManageDocumentsCategoryPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageDocumentsCategoryPage));