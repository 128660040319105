import DashboardPage from '../../pages/dashboard/DashboardPage';
import AddressPage from '../../pages/address/AddressPage';
import ManageAddressPage from '../../pages/address/ManageAddressPage';
import PromoCodesPage from '../../pages/promo_codes/PromoCodesPage';
import ManagePromoCodesPage from '../../pages/promo_codes/ManagePromoCodesPage';
import ClientsPage from '../../pages/clients/ClientsPage';
import ManageClientsPage from '../../pages/clients/ManageClientsPage';
import ArchivePage from '../../pages/archive/ArchivePage';
import CreateProcessPage from '../../pages/process/create';
import EditProcessPage from '../../pages/process/edit';
import ListProcessPage from '../../pages/process/list';
import TasksPage from '../../pages/tasks/TasksPage';
import ManageTasksPage from '../../pages/tasks/ManageTasksPage';
import LogsPage from '../../pages/logs/LogsPage';
import CommunicationsPage from '../../pages/communications/list';
import CommunicationsCreatePage from '../../pages/communications/create';
import CommunicationsDetailPage from '../../pages/communications/detail';
import LemaniaPage from '../../pages/lemania/LemaniaPage';
import CommissionsPage from '../../pages/commissions/CommissionsPage';
import ManageCommissionsPage from '../../pages/commissions/ManageCommissionsPage';
import EmailLogsPage from '../../pages/email_logs/LogsPage';
import ClientStatisticsPage from '../../pages/statistics/clients';
import ServiceStatisticsPage from '../../pages/statistics/services';
import CommissionStatisticsPage from '../../pages/statistics/commissions';
import DocumentsCategoriesPage from '../../pages/documents_categories/DocumentsCategoriesPage';
import ManageDocumentsCategoryPage from '../../pages/documents_categories/ManageDocumentsCategoryPage';
import DocumentsPage from '../../pages/documents/DocumentsPage';
import ManageDocumentPage from '../../pages/documents/ManageDocumentPage';
import InstitutionTemplatesPage from '../../pages/institutions_templates/InstitutionTemplatesPage';
import ManageInstitutionTemplatePage from '../../pages/institutions_templates/ManageInstitutionTemplatePage';
import PendingPaymentsPage from '../../pages/pending_payments/PendingPaymentsPage';
// Old process routes (so if we need to test something in the old pages we can access them quickly)
// import ProcessPage from '../../pages/processOLD/ProcessPage';
// import ManageProcessPage from '../../pages/processOLD/ManageProcessPage';

export default [
  {
    path: '/',
    component: DashboardPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/address',
    component: AddressPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/address/add',
    component: ManageAddressPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/address/:id',
    component: ManageAddressPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/promo-codes',
    component: PromoCodesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/promo-codes/add',
    component: ManagePromoCodesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/promo-codes/:id',
    component: ManagePromoCodesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/clients',
    component: ClientsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/clients/add',
    component: ManageClientsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/clients/:id',
    component: ManageClientsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/archive',
    component: ArchivePage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/archive/:id',
    component: EditProcessPage,
    isAuthenticated: true,
    role: 1,
  },
  // {
  //   path: '/process-old',
  //   component: ProcessPage,
  //   isAuthenticated: true,
  //   role: 1,
  // },
  // {
  //   path: '/process-old/add',
  //   component: ManageProcessPage,
  //   isAuthenticated: true,
  //   role: 1,
  // },
  // {
  //   path: '/process-old/:id',
  //   component: ManageProcessPage,
  //   isAuthenticated: true,
  //   role: 1,
  // },
  {
    path: '/process',
    component: ListProcessPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/process/add',
    component: CreateProcessPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/process/:id',
    component: EditProcessPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/tasks',
    component: TasksPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/tasks/add',
    component: ManageTasksPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/tasks/:id',
    component: ManageTasksPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/logs',
    component: LogsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/communications',
    component: CommunicationsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/communications/add',
    component: CommunicationsCreatePage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/communications/:id',
    component: CommunicationsDetailPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/lemania',
    component: LemaniaPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/commissions',
    component: CommissionsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/commissions/:id',
    component: ManageCommissionsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/email-logs',
    component: EmailLogsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/statistics/clients',
    component: ClientStatisticsPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/statistics/services',
    component: ServiceStatisticsPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/statistics/commissions',
    component: CommissionStatisticsPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/documents/categories',
    component: DocumentsCategoriesPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/documents/categories/add',
    component: ManageDocumentsCategoryPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/documents/categories/:id',
    component: ManageDocumentsCategoryPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/documents/list',
    component: DocumentsPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/documents/list/add',
    component: ManageDocumentPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/documents/list/:id',
    component: ManageDocumentPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/institutions-templates',
    component: InstitutionTemplatesPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/institutions-templates/add',
    component: ManageInstitutionTemplatePage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/institutions-templates/:id',
    component: ManageInstitutionTemplatePage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/pending-payments',
    component: PendingPaymentsPage,
    isAuthenticated: true,
    role: 1
  }
];
