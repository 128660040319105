import { Field, reduxForm, initialize } from 'redux-form';
import { Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import { GetDocument, UpdateDocument, CreateDocument, GetCategoriesList } from '../../infra/requests/DocumentsRequests';
import { FormContainer, BaseForm, SpinLoading } from '../../styles/BasicStyles';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import React, { Component } from 'react';
import SelectInput from '../../components/generic/inputs/SelectInput';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import Dropzone from '../process/components/FileUpload/SingleDropzone';
import TextInput from '../../components/generic/inputs/TextInput';

const validations = FormValidator.make({
  category: 'required',
  file: 'required'
});

class ManageDocumentPage extends Component {
  state = { 
    isNew: false, 
    loading: true,
    categories: []
  };

  componentDidMount = async () => {
    const { match: { params }, dispatch, CloseMenu, user, history } = this.props;

    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    CloseMenu();

    const categories = await GetCategoriesList();

    if(params.id) {
      const result = await GetDocument(params.id);
      
      dispatch(initialize('manage_documents_form', { ...result.data, category: result.data.category?._id }));

      this.setState({ 
        loading: false,
        categories: categories?.data || []
      });
    } 
    else {
      this.setState({
        isNew: true,
        loading: false,
        categories: categories?.data || []
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });

      const { match: { params }, history } = this.props;

      const payload = FlattenToFormData(values);
      const { success } = params.id ? await UpdateDocument(params.id, payload) : await CreateDocument(payload);

      this.setState({ loading: false });
      
      if(success) history.push('/documents/list');
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/documents/list');
  };


  render() {
    const { handleSubmit } = this.props;
    const { isNew, loading, categories } = this.state;

    if(loading) return <SpinLoading />;

    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <React.Fragment>
        <ManageFormHeader
          title='Documentos'
          breadcrumbs={[title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values))
            },
            {
              type: 'secondary',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel
            }
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24} style={{ marginBottom: '20px' }}>
              <Col md={12} xs={24}>
                <Field
                  component={TextInput}
                  name='name'
                  type='text'
                  label={'Nome'}
                  placeholder={'Nome do ficheiro'}
                  notesText='Deixe em branco para usar o nome do ficheiro selecionado'
                />
              </Col>
              <Col md={12} xs={24}>
                <Field
                  component={SelectInput}
                  name={'category'}
                  label={'Categoria *'}
                  placeholder={'Escolha a categoria'}
                  type='select'
                  data={categories}
                  dataKey={'_id'}
                  dataLabel={'title'}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24}>
                <Field 
                  name='file'
                  component={Dropzone}
                />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageDocumentPage = reduxForm({
  form: 'manage_documents_form',
  validate: validations
})(ManageDocumentPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageDocumentPage));
