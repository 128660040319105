/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col } from 'antd';
import { TableFilterSection } from '../../../../styles/BasicStyles';
import React, { useEffect, useState } from 'react';
import TextInput from '../../../../components/generic/inputs/TextInput';
import SelectInput from '../../../../components/generic/inputs/SelectInput';
import DateInput from '../../../../components/generic/inputs/DateInput';

const Filters = ({ institutions, queryChange, isAgent, institutionsTemplates }) => {
  const [filters, setFilters] = useState({
    search: '',
    date: '',
    institution: '',
    template: ''
  });

  let timeout = null;

  useEffect(() => {
    if(timeout) clearTimeout(timeout);
    timeout = setTimeout(constructQuery, 200);
  }, [filters]);

  const onInputChange = (field, value, timeout = 0) => {
    setFilters({
      ...filters,
      [field]: value
    });
  };

  const constructQuery = () => {
    const query = {};
    Object.keys(filters).map((key) => {
      if(filters[key] !== '') {
        query[key] = filters[key];
      }
      return key;
    });

    queryChange(JSON.stringify(query));
  };

  return (
    <TableFilterSection>
      <Row gutter={24}>
        <Col xs={24} md={6}>
          <TextInput
              input={{
                value: filters.search,
                onChange: (event) => onInputChange('search', event.target.value, 1000),
              }}
              meta={{ valid: true }}
              type='text'
              label='Pesquisar'
              placeholder='Procurar por email'
            />
        </Col>
        <Col xs={24} md={6}>
          <DateInput
            label="Data"
            placeholder="Escolher data"
            input={{
              value: filters.date,
              onChange: value => onInputChange('date', value)
            }}
            meta={{valid: true}}
          />
        </Col>
        {
          !isAgent &&
          <>
            <Col xs={24} md={6}>
              <SelectInput
                  label="Instituição"
                  allowClear
                  placeholder="Escolher instituição"
                  data={institutions}
                  dataKey="_id"
                  dataLabel="name"
                  input={{
                    value: filters.institution,
                    onChange: value => onInputChange('institution', value)
                  }}
                  meta={{valid: true}}
                />
            </Col>
            <Col xs={24} md={6}>
              <SelectInput
                label="Template"
                allowClear
                placeholder="Escolher template"
                data={institutionsTemplates}
                dataKey="number"
                dataLabel="title"
                input={{
                  value: filters.template,
                  onChange: value => onInputChange('template', value)
                }}
                meta={{valid: true}}
              />
            </Col>
          </>
        }
      </Row>
    </TableFilterSection>
  );
}

export default Filters;
