import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Tabs, Row, Col, Divider, notification } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetTemplate,
  UpdateTemplate,
  CreateTemplate
} from '../../infra/requests/InstitutionsTemplatesRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab
} from '../../styles/BasicStyles';
import { ExpandWindowContainer, InputContainer, HtmlPreview } from '../email_templates/Styles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextInput from '../../components/generic/inputs/TextInput';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";
import TextAreaInput from '../../components/generic/inputs/TextAreaInput';
import BaseButton from '../../components/generic/buttons/BaseButton';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  title: 'required',
  subject: 'required',
  content: 'required'
});

class ManageInstitutionTemplatePage extends Component {
  state = { 
    isNew: false, 
    loading: true,
    info: undefined,
    viewSize: 1
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu,
      user,
      history
    } = this.props;

    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    CloseMenu();

    if(params.id) {
      const result = await GetTemplate(params.id);
      dispatch(
        initialize('manage_institution_template_form', {
          ...result.data,
        })
      );

      this.setState({ 
        loading: false,
        info: result.data
      });
    } 
    else {
      this.setState({
        isNew: true,
        loading: false
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const { match: { params }, history } = this.props;
      const { info } = this.state;

      if(!info?.content) {
        notification.error({
          message: 'Erro!',
          description: 'Deverá introduzir algum contéudo no email.'
        });
        return this.setState({ loading: false });
      }

      const payload = FlattenToFormData({ ...values, content: info?.content });
      const { success } = params.id ? await UpdateTemplate(params.id, payload) : await CreateTemplate(payload);

      if (success) return history.push('/institutions-templates');
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/institutions-templates');
  };

  onChange = (value) => {
    const { info } = this.state;
    info.content = value;

    this.setState({
      info
    });
  };

  changeView = () => {
    const { viewSize } = this.state;
    const size = viewSize === 1 ? 2 : 1;

    this.setState({
      viewSize: size
    });
  };

  render() {
    const { handleSubmit, activeLanguage, languages } = this.props;
    const { isNew, loading, viewSize, info } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <React.Fragment>
        <ManageFormHeader
          title='Templates para Instituições'
          breadcrumbs={[title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values))
            },
            {
              type: 'secondary',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24}>
              <Col xs={24}>
                <Field
                  component={TextInput}
                  name={'title'}
                  type='text'
                  label={'Título *'}
                  placeholder={'Título'}
                />
              </Col>
            </Row>
            <Divider />
            <Row gutter={24}>
              <Col xs={24}>
                <Field
                  component={TextInput}
                  name={'subject'}
                  type='text'
                  label={'Assunto do Email *'}
                  placeholder={'Assunto'}
                />
              </Col>
            </Row>
            <ExpandWindowContainer style={{ marginTop: '40px' }}>
              <Row gutter={24} style={{ marginBottom: '20px' }}>
                <Col xs={24} offset={0}>
                  <BaseButton
                    icon='fullscreen'
                    text={viewSize === 1 ? 'Expandir Janela' : 'Diminuir Janela' }
                    onClick={() => this.changeView()}
                  />
                </Col>
              </Row>
            </ExpandWindowContainer>
            <Row gutter={24} style={{ marginBottom: '40px' }}>
              <Col md={24} lg={viewSize === 1 ? 12 : 24} offset={0}>
                <InputContainer hasMargin={ viewSize === 1 ? false : true }>
                  <AceEditor
                    placeholder='Introduza o conteúdo do email'
                    mode='html'
                    theme='monokai'
                    name='content'
                    onLoad={editorInstance => {
                      editorInstance.container.style.resize = 'both';
                      document.addEventListener('mouseup', e => (
                        editorInstance.resize()
                      ));
                    }}
                    onChange={this.onChange}
                    fontSize={14}
                    showPrintMargin={false}
                    showGutter={true}
                    highlightActiveLine={true}
                    value={info?.content}
                    setOptions={{
                      enableBasicAutocompletion: false,
                      enableLiveAutocompletion: false,
                      enableSnippets: false,
                      showLineNumbers: true,
                      tabSize: 2
                    }}
                  />
                </InputContainer>
              </Col>
              <Col md={24} lg={viewSize === 1 ? 12 : 24} offset={0}>
                <HtmlPreview
                  dangerouslySetInnerHTML={{
                  __html: info?.content
                  }}
                />
              </Col>
            </Row>
            <Divider />
            <Row gutter={24}>
              <Col md={12} xs={24} offset={0}>
                <DefaultLanguageTab>
                  {GetTranslationDescription(activeLanguage?.code)}
                </DefaultLanguageTab>
                <Field
                  component={TextAreaInput}
                  name={`notification_content.${activeLanguage?.code}`}
                  label={'Notificação para Cliente'}
                  placeholder={'Conteúdo'}
                />
              </Col>
              <Col md={12} xs={24} offset={0}>
                <Tabs>
                  {languages
                    .filter((x) => !x.active)
                    .map((language) => (
                      <TabPane
                        tab={GetTranslationDescription(language.code)}
                        key={language.code}
                      >
                        <Field
                          component={TextAreaInput}
                          name={`notification_content.${language.code}`}
                          label={'Notificação para Cliente'}
                          placeholder={'Conteúdo'}
                        />
                      </TabPane>
                    ))}
                </Tabs>
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageInstitutionTemplatePage = reduxForm({
  form: 'manage_institution_template_form',
  validate: validations,
})(ManageInstitutionTemplatePage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageInstitutionTemplatePage));