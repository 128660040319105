import { TableFilterSection } from '../../styles/BasicStyles';
import { Row, Col } from 'antd';
import React, { Component } from 'react';
import TextInput from '../../components/generic/inputs/TextInput';
import SelectInput from '../../components/generic/inputs/SelectInput';

const options = [
  { _id: 1, title: 'Sim' },
  { _id: 2, title: 'Não' },
];

class PendingPaymentsFilters extends Component {
  state = { 
    search: '',
    process: '',
    client: '',
    active: ''
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map((key) => {
      if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(JSON.stringify(query));
  };

  render() {
    const { processList, clients } = this.props;
    const { search, process, client, active } = this.state;

    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} md={8}>
            <TextInput
              input={{
                value: search,
                onChange: (event) => this.onInputChange('search', event.target.value, 1000),
              }}
              meta={{ valid: true }}
              type='text'
              label='Pesquisar'
              placeholder='Procurar por descrição, processo ou cliente'
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label='Processo'
              allowClear
              placeholder='Escolher processo'
              data={processList}
              dataKey='_id'
              dataLabel='name'
              translatable={false}
              input={{
                value: process,
                onChange: value => this.onInputChange('process', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label='Cliente'
              allowClear
              placeholder='Escolher cliente'
              data={clients}
              dataKey='_id'
              dataLabel='name'
              input={{
                value: client,
                onChange: value => this.onInputChange('client', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={4}>
            <SelectInput
              label="Ativo"
              allowClear
              placeholder="Escolher estado"
              data={options}
              dataKey="_id"
              dataLabel="title"
              input={{
                value: active,
                onChange: value => this.onInputChange('active', value)
              }}
              meta={{valid: true}}
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

export default PendingPaymentsFilters;
