/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Modal, notification } from 'antd';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change as ChangeField, initialize } from 'redux-form';
import { ModalTitle, ModalButtonWrapper, MissingLabel } from '../../styles';
import { FlattenToFormData } from '../../../../infra/services/formdata/TransformToFormData';
import { SendEmailInstitution, SendEmailInstitutionZentralstelle, SendEmailInstitutionZAS } from '../../../../infra/requests/ProcessRequests';
import { ReactSVG } from 'react-svg';
import React, { useEffect, useState } from 'react';
import TextInput from '../../../../components/generic/inputs/TextInput';
import FormValidator from '../../../../infra/services/validations/FormValidator';
import FileUpload from '../../components/FileUpload';
import SelectInput from '../../../../components/generic/inputs/SelectInput';
import BaseButton from '../../../../components/generic/buttons/BaseButton';

const validations = values => {
  let errors = {};

  if(parseInt(values?.institution) === 1) {
    errors = FormValidator.make({
      institution: 'required',
      email: 'required|email'
    })(values);
  }
  else {
    errors = FormValidator.make({
      institution: 'required',
      email: 'required|email',
      template: 'required'
    })(values);
  }

  return errors;
};

const NewInstitutionEmail = ({ 
  open, 
  closeModal, 
  processInfo, 
  currentResponsible, 
  institutions, 
  addressList, 
  baseInstitutions, 
  getInfo, 
  documents, 
  reports, 
  file_template, 
  handleSubmit, 
  dispatch, 
  user, 
  institutionsTemplates 
}) => {
  const [loading, setLoading] = useState(false);
  const [addressSelect, setAddressSelect] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [subjectSelect, setSubjectSelect] = useState(false);
  const [subjectError, setSubjectError] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [institutionInfo, setInstitutionInfo] = useState(null);
  const [showTemplates, setTemplates] = useState(false);
  const [disableInstitution, setDisableInstitution] = useState(false);

  useEffect(() => {
    dispatch(initialize('new_institution_email_form'));
    
    setTemplates(false);
    setAddressSelect(false);
    setAddressError(false);
    setSubjectSelect(false);
    setSubjectError(false);
    setAcceptedFiles([]);
    setRejectedFiles([]);

    // Agents can only send an email to institution "Zentralstelle", so they can't select any other
    if(user?.type === 2) {
      dispatch(ChangeField('new_institution_email_form', 'institution', baseInstitutions[0]._id));
      dispatch(ChangeField('new_institution_email_form', 'email', baseInstitutions[0].email));

      setDisableInstitution(true);
      setInstitutionInfo(baseInstitutions[0]);

      // We need to add the "Avoir de Recherche" file (if it's not already added)
      const fileExists = acceptedFiles.length > 0 && acceptedFiles.findIndex(elem => elem.file_template === 2) >= 0 ? true : false;

      if(!fileExists) {
        setAcceptedFiles([ ...acceptedFiles, { file_template: 2 } ]);
      }
    }
    else {
      setDisableInstitution(false);
      setInstitutionInfo(null);
    }
  }, [open]);

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      /* 
      * In case the user selected a file that needed an address or subject (Procuração for example), don't let submit unless they select something in that field
      * Otherwise, the user thinks he added a file, but he didn't
      */
      if(addressSelect) {
        setAddressError(true);
        setLoading(false);

        return notification.error({
          message: 'Deverá selecionar uma morada para completar o ficheiro selecionado! Caso contrário o ficheiro não será anexado.'
        });
      }
      if(subjectSelect) {
        setSubjectError(true);
        setLoading(false);

        return notification.error({
          message: 'Deverá selecionar um assunto para completar o ficheiro selecionado! Caso contrário o ficheiro não será anexado.'
        });
      }

      const payload = FlattenToFormData({ ...values, process: processInfo._id, selectedFiles: acceptedFiles });

      // Agent
      if(user?.type === 2) {
        const result = await SendEmailInstitutionZentralstelle(payload);

        if(result.success) {
          notification.success({
            message: 'Email enviado com sucesso!'
          });
  
          dispatch(initialize('new_institution_email_form'));

          setInstitutionInfo(null);
          setAddressSelect(false);
          setAddressError(false);
          setSubjectSelect(false);
          setSubjectError(false);
          setAcceptedFiles([]);
          setRejectedFiles([]);
          closeModal();

          await getInfo();
        }
      }
      // Admin
      else {
        let result = undefined;
        if(values?.institution === baseInstitutions[0]._id) result = await SendEmailInstitutionZentralstelle(payload);
        else if(values?.institution === baseInstitutions[1]._id) result = await SendEmailInstitutionZAS(payload);
        else result = await SendEmailInstitution(payload);
  
        if(result?.success) {
          notification.success({
            message: 'Email enviado com sucesso!'
          });
  
          dispatch(initialize('new_institution_email_form'));

          setInstitutionInfo(null);
          setTemplates(false);
          setAddressSelect(false);
          setSubjectSelect(false);
          setAcceptedFiles([]);
          setRejectedFiles([]);
          closeModal();

          await getInfo();
        }
      }

      setLoading(false);
    }
    catch(error) {
      console.log(error);
      setLoading(false);
    }
  }

  const checkSelectedTemplate = (value) => {
    //In these types we must select an address to complete the file
    if(parseInt(value) === 1 || parseInt(value) === 3 || parseInt(value) === 4) {
      setAddressSelect(true);
      setSubjectSelect(false);
    }
    //In this type we must select a subject to complete the file
    else if(parseInt(value) === 10) {
      setAddressSelect(false);
      setSubjectSelect(true);
    }
    //Otherwise we can add the file to the selected array
    else {
      setAddressSelect(false);
      setSubjectSelect(false);
      setAcceptedFiles([ ...acceptedFiles, { file_template: value } ]);

      //Clear Template field
      dispatch(ChangeField('new_institution_email_form', 'file_template', null));
    }
  }

  const checkSelectedAddress = (value) => {
    setAcceptedFiles([ ...acceptedFiles, { file_template, address: value } ]);
    setAddressSelect(false);
    setAddressError(false);

    //Clear Template field
    dispatch(ChangeField('new_institution_email_form', 'file_template', null));

    //Clear Address field
    dispatch(ChangeField('new_institution_email_form', 'address', null));
  }

  const checkSelectedSubject = (value) => {
    setAcceptedFiles([ ...acceptedFiles, { file_template, subject: value } ]);
    setSubjectSelect(false);
    setSubjectError(false);

    //Clear Template field
    dispatch(ChangeField('new_institution_email_form', 'file_template', null));

    //Clear Subject field
    dispatch(ChangeField('new_institution_email_form', 'subject', null));
  }

  const checkInstitution = async (value) => {
    let institutionInfo = undefined, showTemplates = false;

    const findInstitution = institutions.find(elem => elem._id === value);

    if(findInstitution) {
      institutionInfo = findInstitution;
      showTemplates = baseInstitutions.some(item => item._id === value) ? false : true;
    }

    dispatch(ChangeField('new_institution_email_form', 'email', institutionInfo?.email || ''));

    // If we selected "Zentralstelle", we need to add the "Avoir de Recherche" file (if it's not already added)
    if(value == 1) {
      const fileExists = acceptedFiles.length > 0 && acceptedFiles.findIndex(elem => elem.file_template === 2) >= 0 ? true : false;

      if(!fileExists) {
        setAcceptedFiles([ ...acceptedFiles, { file_template: 2 } ]);
      }
    }
    // If we selected "ZAS", we need to add the "Formulaire extrait du compte AVS" file (if it's not already added)
    else if(value == 2) {
      const fileExists = acceptedFiles.length > 0 && acceptedFiles.findIndex(elem => elem.file_template === 7) >= 0 ? true : false;

      if(!fileExists) {
        setAcceptedFiles([ ...acceptedFiles, { file_template: 7 } ]);
      }
    }

    setInstitutionInfo(institutionInfo);
    setTemplates(showTemplates);
  }

  const checkSelectedDocument = (value) => {
    const file = documents.find(elem => elem._id === value);

    setAcceptedFiles([ ...acceptedFiles, { document: value, filename: file?.name || '', size: file?.size || 0 } ]);

    //Clear Document field
    dispatch(ChangeField('new_institution_email_form', 'document', null));
  }

  const checkSelectedReport = (value) => {
    const file = reports.find(elem => elem._id === value);

    setAcceptedFiles([ ...acceptedFiles, { report: value, filename: file?.name || '', size: file?.size || 0 } ]);

    //Clear Report field
    dispatch(ChangeField('new_institution_email_form', 'report', null));
  }

  return (
    <Modal
      visible={open}
      maskClosable={false}
      onCancel={closeModal}
      footer={null}
      width={600}
    >
      <>
        <ModalTitle>Novo Email</ModalTitle>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            component={SelectInput}
            placeholder={'Selecione uma Instituição'}
            name={'institution'}
            type='select'
            data={institutions}
            dataKey={'_id'}
            dataLabel={'name'}
            label={'Instituição *'}
            execAfterChange={checkInstitution}
            disabled={disableInstitution}
          />
          {
            institutionInfo &&
            <>
              <Field
                name='email'
                component={TextInput}
                type='email'
                label='Email do destinatário *'
                placeholder='Email'
                notesText={institutionInfo && !institutionInfo.email ? 'A Instituição selecionada ainda não possui um endereço de email!' : ''}
              />
              {
                showTemplates && 
                <Field
                  component={SelectInput}
                  placeholder={'Selecione um Email Template'}
                  name={'template'}
                  type='select'
                  data={institutionsTemplates}
                  dataKey={'number'}
                  dataLabel={'title'}
                  label={'Email Template *'}
                />
              }
            </>
          }
          {
            // Show alert for "Zentralstelle" or "ZAS" stating that some files will be automatically added
            (institutionInfo?._id == 1 || institutionInfo?._id == 2) &&
            <div style={{ paddingTop: '15px' }}>
              <MissingLabel style={{ padding: '6px 10px' }}>
                <ReactSVG src={`${process.env.REACT_APP_BO_URL}warning.svg`} />
                {
                  institutionInfo?._id == 1 ?
                  <span>O ficheiro "Avoir de Recherche" é adicionado automaticamente ao selecionar a instituição "Zentralstelle", mas pode ser removido se pretender.</span>
                  :
                  institutionInfo?._id == 2 ?
                  <span>O ficheiro "FR formulaire extrait du compte AVS" é adicionado automaticamente ao selecionar a instituição "ZAS", mas pode ser removido se pretender.</span>
                  :
                  null
                }
              </MissingLabel>
            </div>
          }
          <FileUpload 
            addressList={addressList}
            checkSelectedTemplate={checkSelectedTemplate}
            checkSelectedAddress={checkSelectedAddress}
            checkSelectedSubject={checkSelectedSubject}
            checkSelectedDocument={checkSelectedDocument}
            checkSelectedReport={checkSelectedReport}
            addressSelect={addressSelect}
            subjectSelect={subjectSelect}
            acceptedFiles={acceptedFiles}
            setAcceptedFiles={setAcceptedFiles}
            rejectedFiles={rejectedFiles}
            setRejectedFiles={setRejectedFiles}
            documents={documents}
            reports={reports}
            processInfo={processInfo}
            currentResponsible={currentResponsible}
            addressError={addressError}
            subjectError={subjectError}
            user={user}
          />
          <ModalButtonWrapper>
            <BaseButton
              type='primary'
              loading={loading}
              htmlType="submit"
              onClick={handleSubmit(onSubmit)}
              text="Enviar email"
            />
          </ModalButtonWrapper>
        </Form>
      </>
    </Modal>
  );
};

const selector = formValueSelector('new_institution_email_form');

const NewInstitutionEmailForm = reduxForm({
  form: 'new_institution_email_form',
  validate: validations
})(NewInstitutionEmail);

const mapStateToProps = state => ({
  user: state.user,
  file_template: selector(state, 'file_template')
});

export default connect(mapStateToProps)(NewInstitutionEmailForm);
