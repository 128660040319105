/* eslint-disable react-hooks/exhaustive-deps */
import { TabTitleWrapper, TabButtonsWrapper, TabTitle, FileDownload } from '../../styles';
import { GetProcessInstitutionHistory, GetProcessDocuments, GetProcessReportsList } from '../../../../infra/requests/ProcessRequests';
import { GetTemplatesList } from '../../../../infra/requests/InstitutionsTemplatesRequests';
import { getInstitutionTemplate } from '../../../../infra/utils/InstitutionTemplates';
import { GetAddressFullList } from '../../../../infra/requests/AddressRequests';
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { PrimaryColour } from '../../../../styles/Colours';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import Table from '../../../../components/generic/table/Table';
import GetFile from '../../../../infra/utils/GetFile';
import Filters from './Filters';
import NewInstitutionEmailModal from '../../modals/NewInstitutionEmail';
import NewEmailModal from '../../modals/NewEmail';
import ViewEmailContentModal from '../../modals/ViewEmailContent';
import EmailsForm from './Form';

const baseInstitutions = [
  {
    _id: '1',
    name: 'Instituição Zentralstelle (envio de Procuração)',
    email: 'info@zentralstelle.ch'
  },
  {
    _id: '2',
    name: 'Instituição ZAS (envio de Extrato AVS)',
    email: 'eai@zas.admin.ch'
  }
];

const Emails = ({ processInfo, currentResponsible, reloadTab, user, getInfo }) => {
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState('');
  const [rows, setRows] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [columns, setColumns] = useState([]);
  const [newEmail, setNewEmail] = useState(false);
  const [newInstitutionEmail, setNewInstitutionEmail] = useState(false);
  const [viewContent, setViewContent] = useState({ open: false, data: undefined });
  const [documents, setDocuments] = useState([]);
  const [reports, setReports] = useState([]);
  const [institutionsTemplates, setInstitutionsTemplates] = useState([]);

  useEffect(() => {
    const init = async () => await updateInfo();

    init();
  }, [filters, reloadTab]);

  const updateInfo = async () => {
    setLoading(true);

    try {
      const result = await GetProcessInstitutionHistory(processInfo._id, filters);
      const addresslist = await GetAddressFullList();
      const documentsList = await GetProcessDocuments(processInfo._id);
      const reportsList = user?.type === 1 ? await GetProcessReportsList(processInfo._id, '') : [];
      const institutionsTemplatesList = user?.type === 1 ? await GetTemplatesList() : [];

      let finalColumns = [];
      if(user?.type === 2) {
        finalColumns = [
          {
            title: 'Data',
            dataIndex: 'createdAt',
            render: (value) => `${moment(value).format('DD-MM-YYYY, HH:mm')}h`,
            width: '15%',
          },
          {
            title: 'Instituição',
            key: 'institution',
            render: (data) => renderInstitution(data),
            width: '20%',
          },
          {
            title: 'Destinatário',
            dataIndex: 'email',
            render: (value) => value,
            width: '15%',
          },
          {
            title: 'Conteúdo',
            key: 'content',
            render: (data) => renderContent(data),
            width: '20%',
          },
          {
            title: 'Ficheiro Anexado',
            dataIndex: 'files',
            render: (value) => renderFiles(value),
            width: '30%',
          }
        ];
      }
      else {
        finalColumns = [
          {
            title: 'Data',
            dataIndex: 'createdAt',
            render: (value) => `${moment(value).format('DD-MM-YYYY, HH:mm')}h`,
            width: '15%',
          },
          {
            title: 'Instituição',
            key: 'institution',
            render: (data) => renderInstitution(data),
            width: '20%',
          },
          {
            title: 'Destinatário',
            dataIndex: 'email',
            render: (value) => value,
            width: '15%',
          },
          {
            title: 'Template / Conteúdo',
            key: 'template',
            render: (data) => renderContent(data, institutionsTemplatesList?.data || []),
            width: '20%',
          },
          {
            title: 'Ficheiro(s) Anexado(s)',
            dataIndex: 'files',
            render: (value) => renderFiles(value),
            width: '30%',
          }
        ];
      }

      const finalDocumentsList = [];
      if(documentsList?.data?.length > 0) {
        documentsList.data.forEach(elem => {
          finalDocumentsList.push({
            _id: elem.file._id,
            name: elem.file.filename,
            size: elem.file.length
          });
        });
      }

      const finalReportsList = [];
      if(reportsList?.data?.length > 0) {
        reportsList.data.forEach(elem => {
          finalReportsList.push({
            _id: elem.file._id,
            name: elem.file.filename,
            size: elem.file.length
          });
        });
      }

      //Add a fixed Institution to the Addresses List
      const institutionsList = [...addresslist?.data] || [];
      institutionsList.unshift(...baseInstitutions);

      setRows(result?.data || []);
      setInstitutions(institutionsList);
      setAddressList(addresslist?.data || []);
      setColumns(finalColumns);
      setDocuments(finalDocumentsList);
      setReports(finalReportsList);
      setInstitutionsTemplates(institutionsTemplatesList?.data || []);
      setLoading(false);
    } 
    catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const handleFilterChange = (filters) => {
    setFilters(filters);
  };

  const renderFiles = (files) => {
    if(files?.length > 0) {
      return files.map((file, index) => (
        <FileDownload
          key={index}
          onClick={() => GetFile(file._id)}
        >
          <span>{file.filename}</span>
          <ReactSVG src={`${process.env.REACT_APP_BO_URL}download.svg`} />
        </FileDownload>
      ));
    }
    return null;
  };

  const renderInstitution = (data) => {
    if(data.isZentralstelle) return baseInstitutions[0].name;
    else if(data.isZAS) return baseInstitutions[1].name;
    return data.institution?.name || '-';
  };

  const renderContent = (data, list = []) => {
    if(data.template) return getInstitutionTemplate(list, data.template)?.title;

    if(data.email_subject && data.email_content)
      return (
        <span 
          style={{ cursor: 'pointer', color: PrimaryColour, textDecoration: 'underline' }} 
          onClick={() => setViewContent({ open: true, data: { subject: data.email_subject, content: data.email_content }})}
        >
          Ver Conteúdo
        </span>
      );

    return '-';
  };

  return (
    <>
      <EmailsForm 
        processInfo={processInfo} 
        getInfo={getInfo}
      />
      <TabTitleWrapper>
        <TabTitle>Emails</TabTitle>
        {
          !processInfo?.archived && user?.type !== 3 &&
          <TabButtonsWrapper>
            <BaseButton
              type={'black'}
              text={'Enviar novo email'}
              onClick={() => setNewEmail(true)}
              disabled={loading}
            />
            <BaseButton
              type={'primary'}
              text={'Enviar novo email (Instituição)'}
              onClick={() => setNewInstitutionEmail(true)}
              disabled={loading}
            />
          </TabButtonsWrapper>
        }
      </TabTitleWrapper>
      <Filters
        queryChange={handleFilterChange}
        institutions={institutions}
        institutionsTemplates={institutionsTemplates}
        isAgent={user?.type === 2}
      />
      <Table
        columns={columns}
        loading={loading}
        rows={rows}
        showHeader={true}
        emptyIcon='warning'
        emptyText={'Não existem Emails enviados!'}
        rowKey={'_id'}
        hasPagination={false}
      />
      {
        !processInfo.archived && newInstitutionEmail &&
        <NewInstitutionEmailModal
          open={newInstitutionEmail}
          closeModal={() => setNewInstitutionEmail(false)}
          processInfo={processInfo}
          currentResponsible={currentResponsible}
          institutions={institutions}
          addressList={addressList}
          baseInstitutions={baseInstitutions}
          getInfo={getInfo}
          documents={documents}
          reports={reports}
          institutionsTemplates={institutionsTemplates}
        />
      }
      {
        !processInfo.archived && newEmail &&
        <NewEmailModal
          open={newEmail}
          closeModal={() => setNewEmail(false)}
          processInfo={processInfo}
          currentResponsible={currentResponsible}
          institutions={institutions}
          addressList={addressList}
          getInfo={getInfo}
          documents={documents}
          reports={reports}
        />
      }
      {
        viewContent.open &&
        <ViewEmailContentModal
          open={viewContent.open}
          closeModal={() => setViewContent({ open: false, data: undefined })}
          data={viewContent.data}
        />
      }
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(Emails);
