import client from '../config/AxiosConfig';

export const GetCategories = async (currentPage, limit, filters) => await client.get(`/documents/categories/${currentPage}/${limit}?filter=${filters}`);

export const GetCategoriesList = async () => await client.get(`/documents/categories`);

export const DeleteCategory = async (id) => await client.delete(`/documents/categories/${id}`);

export const GetCategory = async (id) => await client.get(`/documents/categories/${id}`);

export const CreateCategory = async (data) => await client.post(`/documents/categories`, data);

export const UpdateCategory = async (id, data) => await client.put(`/documents/categories/${id}`, data);

export const GetDocuments = async (currentPage, limit, filters) => await client.get(`/documents/${currentPage}/${limit}?filter=${filters}`);

export const DeleteDocument = async (id) => await client.delete(`/documents/${id}`);

export const GetDocument = async (id) => await client.get(`/documents/${id}`);

export const CreateDocument = async (data) => await client.post(`/documents`, data);

export const UpdateDocument = async (id, data) => await client.put(`/documents/${id}`, data);

export const ToggleFavorite = async (id, data) => await client.put(`/documents/${id}/toggle-favorite`, data);
