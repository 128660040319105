import { reduxForm, initialize } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import {
  GetDocument,
  GetDocumentText,
  UpdateDocumentText
} from '../../../infra/requests/DigitalDocumentsRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab
} from '../../../styles/BasicStyles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../../redux/Menu/menu.actions';
import { Tabs, Row, Col } from 'antd';
import { Field } from 'redux-form';
import { GetTranslationDescription } from '../../../infra/services/translations/AvailableTranslations';
import React, { Component } from 'react';
import ManageFormHeader from '../../../components/base/ManageFormHeader';
import DraftInput from '../../../components/generic/inputs/DraftInput';
import TextInput from '../../../components/generic/inputs/TextInput';
import ParamsList from './ParamsList';
const TabPane = Tabs.TabPane;

class ManageDocumentTextPage extends Component {
  state = { 
    loading: true,
    initialValues: null,
    document: null,
    newParam: false
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu,
      user,
      history
    } = this.props;

    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    CloseMenu();

    const document = await GetDocument(params.document);
    const result = await GetDocumentText(params.text);

    dispatch(initialize('manage_document_text_form', { 
      ...result.data
    }));

    this.setState({ 
      loading: false,
      initialValues: result?.data,
      document: document?.data
    });
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });

      const { match: { params }, history } = this.props;
      const { initialValues, document } = this.state;

      const payload = {
        document: initialValues?.document?._id,
        name: initialValues?.name,
        tag: initialValues?.tag,
        ...values
      };

      const { success } = await UpdateDocumentText(params.text, payload);

      if(success) return history.push(`/digital-documents/list/${document?.identifier}`);
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  addParam = (key) => {
    
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    const { document } = this.state;
    return history.push(`/digital-documents/list/${document?.identifier}`);
  };

  render() {
    const { handleSubmit, pristine, languages, activeLanguage } = this.props;
    const { loading, document } = this.state;

    if (loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <ManageFormHeader
          title='Documentos Digitais'
          breadcrumbs={[`${document?.title && document?.title[activeLanguage?.code]}`, 'Conteúdos', 'Editar']}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'secondary',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24}>
              <Col md={12} xs={24} offset={0}>
                <DefaultLanguageTab>
                  {GetTranslationDescription(activeLanguage?.code)}
                </DefaultLanguageTab>
                <Field
                  component={TextInput}
                  name={`title.${activeLanguage?.code}`}
                  type='text'
                  label={'Título'}
                  placeholder={'Título'}
                />
                <Field
                  component={DraftInput}
                  name={`text.${activeLanguage?.code}`}
                  label={'Texto'}
                  placeholder={'Texto'}
                />
              </Col>
              <Col md={12} xs={24} offset={0}>
                <Tabs>
                  {languages
                    .filter((x) => !x.active)
                    .map((language) => (
                      <TabPane
                        tab={GetTranslationDescription(language.code)}
                        key={language.code}
                      >
                        <Field
                          component={TextInput}
                          name={`title.${language.code}`}
                          type='text'
                          label={'Título'}
                          placeholder={'Título'}
                        />
                        <Field
                          component={DraftInput}
                          name={`text.${language.code}`}
                          label={'Texto'}
                          placeholder={'Texto'}
                        />
                      </TabPane>
                    ))}
                </Tabs>
              </Col>
            </Row>
            <Field
              component={ParamsList}
              name='params'
            />
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageDocumentTextPage = reduxForm({
  form: 'manage_document_text_form'
})(ManageDocumentTextPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageDocumentTextPage));