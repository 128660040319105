import client from '../config/AxiosConfig';

export const GetTemplates = async (currentPage, limit, filters) => await client.get(`/institutions_templates/${currentPage}/${limit}?filter=${filters}`);

export const GetTemplate = async (id) => await client.get(`/institutions_templates/${id}`);

export const CreateTemplate = async (data) => await client.post(`/institutions_templates`, data);

export const UpdateTemplate = async (id, data) => await client.put(`/institutions_templates/${id}`, data);

export const GetTemplatesList = async () => await client.get(`/institutions_templates`);
