import { PageContainer, PageTitle, HeaderContainer, HeaderTitle, HeaderButtonsContainer } from '../../styles/BasicStyles';
import { GetDocuments, DeleteDocument, GetCategoriesList, ToggleFavorite } from '../../infra/requests/DocumentsRequests';
import { GetAdminList } from '../../infra/requests/UsersRequests';
import { connect } from 'react-redux';
import { StateLabel } from '../address/AddressStyles';
import { FileDownload } from '../process/styles';
import { ReactSVG } from 'react-svg';
import React, { Component } from 'react';
import BaseButton from '../../components/generic/buttons/BaseButton';
import DocumentsFilters from './DocumentsFilters';
import moment from 'moment-timezone';
import Table from '../../components/generic/table/Table';
import ButtonActions from '../../components/generic/buttons/ButtonActions';
import GetFile from '../../infra/utils/GetFile';

class TasksPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Data',
        dataIndex: 'createdAt',
        render: (value) => `${moment(value).format('DD-MM-YYYY, HH:mm')}h`,
        width: '12%',
      },
      {
        title: 'Criado por',
        dataIndex: 'created_by',
        render: (value) => value?.name || 'N/A',
        width: '15%',
      },
      {
        title: 'Nome',
        dataIndex: 'name',
        width: '22%'
      },
      {
        title: 'Ficheiro',
        key: 'file',
        render: (data) => 
          <FileDownload onClick={(e) => { e.stopPropagation(); e.preventDefault(); GetFile(data.file?._id); }}>
            <span>{data.file?.filename}</span>
            <ReactSVG src={`${process.env.REACT_APP_BO_URL}download.svg`} />
          </FileDownload>,
        width: '20%'
      },
      {
        title: 'Categoria',
        dataIndex: 'category',
        render: (value) => value?.title || 'N/A',
        width: '15%'
      },
      {
        title: 'Favorito',
        dataIndex: 'favorite',
        render: (value) => this.renderFavorite(value),
        width: '10%',
      },
      {
        title: 'Ações',
        render: (data) => this.renderActions(data)
      }
    ],
    rows: [],
    total: 0,
    categories: [],
    users: []
  };

  componentDidMount = async () => {
    const { user, history } = this.props;

    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/documents/list/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  renderActions = (data) => {
    const { history } = this.props;

    return (
      <ButtonActions 
        icon={'dots'} 
        iconType={'fill'} 
        options={[
          {
            onClick: () => history.push(`/documents/list/${data._id}`),
            label: 'Editar' 
          },
          {
            popConfirmtitle: data.favorite ? 'Quer remover este Documento dos favoritos?' : 'Quer adicionar este Documento aos favoritos?',
            onClick: () => this.toggleFavorite(data),
            label: data.favorite ? ' Remover dos Favoritos' : ' Adicionar aos Favoritos'
          },
          {
            popConfirmtitle: 'Tem a certeza que quer remover este documento?',
            onClick: () => this.removeDocument(data),
            label: 'Remover' 
          }
        ]} 
      />
    );
  };

  renderFavorite = (value) => {
    if(value) return <StateLabel bgColor='#389e0d'>Sim</StateLabel>;
    return <StateLabel bgColor='#cf1322'>Não</StateLabel>;
  };

  removeDocument = async (data) => {
    try {
      await DeleteDocument(data._id);
      await this.updateDataTable();
    } 
    catch (e) {
      console.error(e);
    }
  };

  toggleFavorite = async (record) => {
    this.setState({ loading: true });
    await ToggleFavorite(record._id, { favorite: !record.favorite });
    await this.updateDataTable();
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetDocuments(currentPage, pageSize, filters);
      const categories = await GetCategoriesList();
      const users = await GetAdminList();

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0,
        categories: categories?.data || [],
        users: users?.data || []
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize, categories, users } = this.state;
    const { history, user } = this.props;

    return (
      <>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle noMarginBottom={true}>Lista de Documentos</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            {
              user?.type !== 3 &&
              <BaseButton
                customIcon={'plus'}
                text={'Adicionar documento'}
                onClick={() => history.push('/documents/list/add')}
              />
            }
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <DocumentsFilters 
            queryChange={this.handleFilterChange} 
            categories={categories}
            users={users}
          />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Documentos inseridao!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(TasksPage);
