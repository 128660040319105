/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Modal, Popconfirm, notification } from 'antd';
import { connect } from 'react-redux';
import { Field, reduxForm, initialize } from 'redux-form';
import { ModalTitle, ModalButtonWrapper } from '../../styles';
import { UpdatePayment, SendPaymentEmail } from '../../../../infra/requests/ProcessRequests';
import { PaymentMethods } from '../../../../infra/utils/PaymentMethods';
import { PaymentStates } from '../../../../infra/utils/ProcessStates';
import { AmountWrapper } from '../NewPayment/styles';
import { Currencies, getCurrency } from '../../../../infra/utils/Currencies';
import { Separator } from '../../../../styles/BasicStyles';
import { Row, DateResent } from './styles';
import React, { useEffect, useState } from 'react';
import FormValidator from '../../../../infra/services/validations/FormValidator';
import TextInput from '../../../../components/generic/inputs/TextInput';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import SelectInput from '../../../../components/generic/inputs/SelectInput';
import DateInput from '../../../../components/generic/inputs/DateInput';
import CurrencyInput from '../../../../components/generic/inputs/CurrencyInput';
import CheckboxInput from '../../../../components/generic/inputs/CheckboxInput';
import moment from 'moment-timezone';

const validations = FormValidator.make({
  date: 'required',
  description: 'required',
  currency: 'required',
  amount: 'required',
  payment_method: 'required',
  payment_state: 'required'
});

const EditPayment = ({ openModal, closeModal, getInfo, paymentModalInfo, handleSubmit, dispatch }) => {
  const [loading, setLoading] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [showSendEmail, setSendEmail] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState('');

  useEffect(() => {
    setSendEmail(paymentModalInfo?.payment_method === 2 || paymentModalInfo?.payment_method === 3 ? true: false);
    setCurrentCurrency(paymentModalInfo?.currency === 1 ? 'CHF' : paymentModalInfo?.currency === 2 ? '€' : '');
  }, [openModal]);

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const result = await UpdatePayment(values._id, values);

      if(result?.success) {
        notification.success({
          message: 'Pagamento atualizado com sucesso!'
        });

        dispatch(initialize('edit_payment_form'));

        closeModal();

        // Redirect to Payments page after reloading the info
        await getInfo(true);
      }

      setLoading(false);
    }
    catch(error) {
      console.log(error);
      setLoading(false);
    }
  }

  const sendEmail = async () => {
    try {
      setLoadingEmail(true);
      
      const result = await SendPaymentEmail({ payment: paymentModalInfo._id });

      if(result.success) {
        notification.success({
          message: 'Email enviado com sucesso!'
        });

        closeModal();

        // Redirect to Payments page after reloading the info
        await getInfo(true);
      }

      setLoadingEmail(false);
    }
    catch (e) {
      console.error(e);
      setLoadingEmail(false);
    }
  }

  return (
    <Modal
      visible={openModal}
      maskClosable={false}
      onCancel={closeModal}
      footer={null}
    >
      <>
        <ModalTitle>Editar Pagamento</ModalTitle>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            component={DateInput}
            name={'date'}
            label={'Data *'}
            placeholder={'Selecione uma data'}
          />
          <Field
            component={TextInput}
            name={'description'}
            type={'text'}
            label={'Descrição *'}
            placeholder={'Insira uma descrição'}
          />
          <AmountWrapper>
            <Field
              component={SelectInput}
              name={'currency'}
              label={'Moeda *'}
              placeholder={'Escolha a moeda'}
              type='select'
              data={Currencies}
              dataKey={'_id'}
              dataLabel={'name'}
              execAfterChange={(value) => setCurrentCurrency(getCurrency(parseInt(value)))}
            />
            <Field
              component={CurrencyInput}
              name={'amount'}
              label={'Montante *'}
              suffix={currentCurrency}
            />
          </AmountWrapper>
          <div style={{ margin: '6px 0' }}>
            <Field
              component={CheckboxInput}
              name={`tax_included`}
              label={'IVA incluído?'}
              importantLabel={true}
              inlineCheckbox={true}
            />
          </div>
          <Field
            component={SelectInput}
            name={'payment_method'}
            label={'Método de Pagamento *'}
            placeholder={'Escolha o método'}
            type='select'
            data={PaymentMethods.filter(elem => elem.allowInPayments)}
            dataKey={'_id'}
            dataLabel={'name'}
            execAfterChange={(value) => setSendEmail(parseInt(value) === 2 || parseInt(value) === 3 ? true: false)}
          />
          <Field
            component={SelectInput}
            name={'payment_state'}
            label={'Estado de Pagamento *'}
            placeholder={'Escolha o estado'}
            type='select'
            data={PaymentStates.filter(elem => elem.allowInPayments)}
            dataKey={'_id'}
            dataLabel={'name'}
          />
          {
            showSendEmail &&
            <>
              <Separator />
              <Row>
                <Popconfirm
                  placement='topRight'
                  title='Pretende enviar um email com os dados para pagamento?'
                  onConfirm={sendEmail}
                >
                  <BaseButton
                    type='black'
                    size='small'
                    text='Enviar Email'
                    loading={loadingEmail}
                    disabled={loading}
                  />
                </Popconfirm>
                <DateResent>
                  Último envio: 
                  <span>
                    {
                      paymentModalInfo?.payment_method === 2 && paymentModalInfo?.bankTransferDateResent ? 
                      `${moment(paymentModalInfo.bankTransferDateResent).format('DD-MM-YYYY, HH:mm')}h` 
                      : 
                      paymentModalInfo?.payment_method === 3 && paymentModalInfo?.postalDateResent ? 
                      `${moment(paymentModalInfo.postalDateResent).format('DD-MM-YYYY, HH:mm')}h` 
                      : 
                      'n/a'
                    }
                  </span>
                </DateResent>
              </Row>
              <Separator />
            </>
          }
          <ModalButtonWrapper>
            <BaseButton
              type='primary'
              loading={loading}
              disabled={loadingEmail}
              htmlType='submit'
              onClick={handleSubmit(onSubmit)}
              text='Guardar'
            />
          </ModalButtonWrapper>
        </Form>
      </>
    </Modal>
  );
};

const EditPaymentForm = reduxForm({
  form: 'edit_payment_form',
  validate: validations
})(EditPayment);

export default connect()(EditPaymentForm);
